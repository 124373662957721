import { DefaultTheme } from 'styled-components';

const colors = {
  white: {
    regular: '#FFFFFF',
  },
  black: {
    regular: '#111111',
    hover: '#2D2D2D',
  },
  primary: {
    regular: '#EAEAEA',
    hover: '#B3B4BC',
  },
  secondary: {
    regular: '#0000FF',
    hover: '#5F5FFF',
  },
  tertiary: {
    regular: '#FA826E',
    hover: '#EA7966',
    light: '#FCC0B7',
  },
  quaternary: {
    regular: '#00FFC8',
    hover: '#07E2B3',
    light: '#80FFE3',
  },
  links: {
    hover: '#0000FF',
  },
  logoColor: '#111111',
};

const spacing = {
  xl: '192px',
  large: '96px',
  medium: '48px',
  block: '32px',
  regular: '24px',
  small: '16px',
  xsmall: '8px',
  xxsmall: '4px',
  menuHeight: 'var(--header-height)',
};

const fonts = {
  families: {
    regular: 'var(--font-regular)',
  },
  weights: {
    regular: 400,
    bold: 700,
  },
};

export const defaultTheme: DefaultTheme = {
  colors: colors,
  spacing: spacing,
  fonts: fonts,
};
