import { I18NConfig } from 'next/dist/server/config-shared';

import { getDomain } from './getDomain';
import { getPrefix } from './getPrefix';

export function getBaseUrl(
  input: I18NConfig,
  locale: string,
  defaultDomain: string,
  skipPrefix?: boolean,
): string {
  const prefix = skipPrefix ? '' : getPrefix(input, locale);
  const domain = getDomain(input, locale, defaultDomain).replace(/\/$/, '');
  if (process.env['OVERRIDE_BASE_URL']) {
    return `${process.env['OVERRIDE_BASE_URL'].replace(/\/$/, '')}${prefix}`;
  }
  return `https://${domain.replace(/\/$/, '')}${prefix}`;
}
