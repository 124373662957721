import { Language, Market } from '@hultafors/shared/types';

export const filterMarkets = (markets: Market[], prefix: string): Market[] => {
  const marketFilter = (market: Market) => {
    if (process.env['NODE_ENV'] === 'test') {
      return true;
    }
    if (market.market === 'com') {
      return true;
    }
    return (
      process.env[`${prefix}_MARKET_${market.market}`.toUpperCase()] === 'true'
    );
  };

  return (
    markets.filter(marketFilter).map((market) => {
      const marketKey = market.market.toUpperCase();
      const languageFilter = (language: Language) => {
        if (process.env['NODE_ENV'] === 'test') {
          return true;
        }
        if (!language.urlPrefix) {
          return true;
        }
        return (
          process.env[
            `${prefix}_LOCALE_${language.urlPrefix}_${marketKey}`.toUpperCase()
          ] === 'true'
        );
      };
      const languages = market.languages?.filter(languageFilter) || [];
      return { ...market, languages };
    }) || []
  );
};
