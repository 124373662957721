// @ts-check

const { prodDomains, stageDomains, devDomains } = require('./next.domains');

/** @type {() => import('next/dist/server/config-shared').I18NConfig} */
const i18n = () => {
  const locales = [
    'en',
    'da-dk',
    'de-de',
    'en-ca',
    'en-gb',
    'en-ie',
    'en-us',
    'fi-fi',
    'nb-no',
    'nl-nl',
    'sv-se',
  ];

  /** @type { import('next/dist/server/config-shared').I18NConfig} */
  const config = {
    locales,
    defaultLocale: 'en',
    localeDetection: false,
  };

  if (process.env['MODE'] && process.env['MODE'] === 'development') {
    config.domains = devDomains;
  }
  if (
    process.env['MODE']
    && process.env['MODE'] !== 'development'
    && process.env['MODE'] === 'staging'
  ) {
    config.domains = stageDomains;
  }
  if (
    process.env['MODE']
    && process.env['MODE'] !== 'development'
    && process.env['MODE'] !== 'staging'
  ) {
    config.domains = prodDomains;
  }

  return config;
};

module.exports = i18n;
