// @ts-check

/** @type {Array<import('next/dist/server/config-shared').DomainLocale>} */
const prodDomains = [
  {
    domain: 'www.hellbergsafety.com',
    defaultLocale: 'en',
    locales: ['en'],
  },
  {
    domain: 'www.hellbergsafety.de',
    defaultLocale: 'de-de',
    locales: ['de-de'],
  },
  {
    domain: 'www.hellbergsafety.dk',
    defaultLocale: 'da-dk',
    locales: ['da-dk'],
  },
  {
    domain: 'www.hellbergsafety.fi',
    defaultLocale: 'fi-fi',
    locales: ['fi-fi'],
  },
  {
    domain: 'www.hellbergsafety.ie',
    defaultLocale: 'en-ie',
    locales: ['en-ie'],
  },
  {
    domain: 'www.hellbergsafety.nl',
    defaultLocale: 'nl-nl',
    locales: ['nl-nl'],
  },
  {
    domain: 'www.hellbergsafety.no',
    defaultLocale: 'nb-no',
    locales: ['nb-no'],
  },
  {
    domain: 'www.hellbergsafety.se',
    defaultLocale: 'sv-se',
    locales: ['sv-se'],
  },
  {
    domain: 'www.hellbergsafety.co.uk',
    defaultLocale: 'en-gb',
    locales: ['en-gb'],
  },
  {
    domain: 'www.hellbergsafety.ca',
    defaultLocale: 'en-ca',
    locales: ['en-ca'],
  },
  {
    domain: 'www.hellbergsafety.us',
    defaultLocale: 'en-us',
    locales: ['en-us'],
  },
];

/** @type {import('next/dist/server/config-shared').DomainLocale[]} */
const stageDomains = [
  {
    domain: 'stage.hellbergsafety.com',
    defaultLocale: 'en',
    locales: ['en'],
  },
  {
    domain: 'stage-de.hellbergsafety.com',
    defaultLocale: 'de-de',
    locales: ['de-de'],
  },
  {
    domain: 'stage-dk.hellbergsafety.com',
    defaultLocale: 'da-dk',
    locales: ['da-dk'],
  },
  {
    domain: 'stage-fi.hellbergsafety.com',
    defaultLocale: 'fi-fi',
    locales: ['fi-fi'],
  },
  {
    domain: 'stage-ie.hellbergsafety.com',
    defaultLocale: 'en-ie',
    locales: ['en-ie'],
  },
  {
    domain: 'stage-nl.hellbergsafety.com',
    defaultLocale: 'nl-nl',
    locales: ['nl-nl'],
  },
  {
    domain: 'stage-no.hellbergsafety.com',
    defaultLocale: 'nb-no',
    locales: ['nb-no'],
  },
  {
    domain: 'stage-se.hellbergsafety.com',
    defaultLocale: 'sv-se',
    locales: ['sv-se'],
  },
  {
    domain: 'stage-uk.hellbergsafety.com.uk',
    defaultLocale: 'en-gb',
    locales: ['en-gb'],
  },
  {
    domain: 'stage-ca.hellbergsafety.com',
    defaultLocale: 'en-ca',
    locales: ['en-ca'],
  },
  {
    domain: 'stage-us.hellbergsafety.com',
    defaultLocale: 'en-us',
    locales: ['en-us'],
  },
];

/** @type {Array<import('next/dist/server/config-shared').DomainLocale>} */
const devDomains = [
  {
    domain: 'localhost',
    defaultLocale: 'en',
    locales: [
      'en',
      'da-dk',
      'de-de',
      'en-ca',
      'en-gb',
      'en-ie',
      'en-us',
      'fi-fi',
      'nb-no',
      'nl-nl',
      'sv-se',
    ],
  },
];

module.exports = { prodDomains, stageDomains, devDomains };
